$(document).ready(function () {
    
    if ($(".home").is(":visible")) {
    	$.each($('.height-equalizer-wrapper'), function () {
            var totalChildEqualizers = $(this).find('.height-equalizer');
            var totalChildrenHeightArr = new Array();
            var indexOfMax;
            $.each(totalChildEqualizers, function () {
                totalChildrenHeightArr.push($(this).outerHeight());
            });
            indexOfMax = totalChildrenHeightArr.indexOf(Math.max.apply(Math, totalChildrenHeightArr));
            if ($('.height-equalizer').is(":visible")) {
                totalChildEqualizers.outerHeight($(totalChildEqualizers[indexOfMax]).outerHeight() + 20);
            }
            else {
                totalChildEqualizers.outerHeight($(totalChildEqualizers[indexOfMax]).outerHeight());
            }
        });

	    $(".clients").on("init", function () {
            $(".clients").removeClass("dont-break");
        });

        if($('.clients').length > 0 && !$('.section_3').hasClass('hide')){
     		$('.clients').slick({
                arrows: true,
                autoplay: false,
                centerMode: false,
                slidesToShow: 4,
                slidesToScroll: 1,
                dots: false,
                infinite: true,
                lazyLoad: 'ondemand',
                prevArrow: '<span class="navArrow_prev navArrow"><i class="fa fa-chevron-left"></i></span>',
                nextArrow: '<span class="navArrow_next navArrow"><i class="fa fa-chevron-right"></i></span>',
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            dots: false
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            initialSlide: 1,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode: true
                        }
                    }
                ]
            });
        }

        if($('.testimonials').length > 0 && !$('.section_7').hasClass('hide')){
            $('.testimonials').slick({
                centerMode: false,
                centerPadding: '0px',
                slidesToShow: 2,
                arrows: true,
                dots: false,
                prevArrow: '<span class="navArrow_prev navArrow"><i class="fa fa-chevron-left"></i></span>',
                nextArrow: '<span class="navArrow_next navArrow"><i class="fa fa-chevron-right"></i></span>',
                responsive: [
                    {
                      breakpoint: 992,
                        settings: {
                            arrows: false,
                            centerMode: false,
                            slidesToShow: 2,
                            dots: true,
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            arrows: true,
                            centerMode: false,
                            centerPadding: '0px',
                            slidesToShow: 1,
                            dots: false,
                        }
                    }
                ]
            });
        }
    }
    
    if(another_user == 1){
        $("#another-user-modal").modal('show');
    }
    
    if(another_user_update_availability == 1){
        $("#another-user-update-availabilty").modal('show');
    }
    
    if(already_login == 'already_login'){
        $("#already-login-modal").modal('show');
    }

    if(job_apply == 'another_user'){
        $("#job_apply").modal('show');
    }

    if(auto_action == 'verified'){
        $("#email-verification-modal").modal('show');
    } 

    if(auto_action == 'reset_password'){
        $("#reset-password-modal").modal('show');
    }

    if(auto_action == 'welcome'){
        $("#welcome-modal").modal('show');
    } 

    if(auto_action == 'availability'){
        $("#availability-modal").modal('show');
    }

    $('#rank_availability').validate({

        rules: {
            applied_rank: {
                required: true
            },
            date_avaibility: {
                required: true
            }
        }

    });

    $(document).on('click','.availability_submit_btn',function (e) {
        e.preventDefault();
        var l = Ladda.create(this);
        if($('#rank_availability').valid()){
            l.start();
            $.ajax({
                type: "POST",
                url: $("#rank_availability").attr('action'),
                data: $("#rank_availability").serialize(),
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content'),
                },
                statusCode: {
                    200:function (response) {
                        l.stop();
                        toastr.success(response.message,"Success");
                        setTimeout(function(){
                            if(response.redirect_url.length > 0){
                                window.location.href = response.redirect_url;
                            }else{
                                window.location.reload();
                            }
                        },1200);
                    },
                    400:function (response) {
                        toastr.success("Error","Error");
                        l.stop();
                    }
                }
            });
        }
    });


    //end
    $('#contact-us-modal-form').validate({

        rules: {
            name: {
                minlength: 2,
                required: true
            },
            email: {
                required: true,
                email: true
            },
            phone: {
                required: true,
                number: true,
                minlength: 10
            },
            message: {
                required: true
            },
            recaptcha1: {
                required: true
            }
        },
        messages: {
            name: {
                required: "Please specify your first name"
            },
            email: {
                required: "We need your email address to contact you",
                email: "Your email address must be in the format of name@domain.com"
            },
            phone: {
                number: "Please enter valid Mobile number"
            },
            recaptcha1: {
                required: "Recaptcha required"
            }
        }
    });

     $('#reach-us-modal-form').validate({

        rules: {
            name: {
                minlength: 2,
                required: true
            },
            email: {
                required: true,
                email: true
            },
            phone: {
                required: true,
                number: true,
                minlength: 10
            },
            message: {
                required: true
            },
            recaptcha2: {
                required: true
            }
        },
        messages: {
            name: {
                required: "Please specify your first name"
            },
            email: {
                required: "We need your email address to contact you",
                email: "Your email address must be in the format of name@domain.com"
            },
            phone: {
                number: "Please enter valid Mobile number"
            },
            recaptcha2: {
                required: "Recaptcha required"
            }
        }
    });

    $(document).on('click',"#contact-us-submit-button",function(e){
        e.preventDefault();
        var l = Ladda.create(this)
        var captcha = $("#g-recaptcha-response").val();
        if($('#contact-us-modal-form').valid()){
            if(captcha){
                l.start();
                $.ajax({
                    type: "POST",
                    url: $("#contact-us-modal-form").attr('action'),
                    data: $("#contact-us-modal-form").serialize(),
                    statusCode: {
                        200:function (response) {
                            $('#contact-us-modal-form').find("input[type=text],input[type=email], textarea").val("");
                            $("#contact-us-modal").modal('hide');
                            toastr.success("Your enquiry has been send to Admin. We will contact you soon.","Success");
                            l.stop();
                        },
                        400:function (response) {
                            $('#contact-us-modal-form').find("input[type=text],input[type=email], textarea").val("");
                            toastr.success("Error","Error");
                            l.stop();
                        }
                    }
                });
            }
        }
    });

    $(document).on('click',"#reach-us-submit-button",function(e){
        e.preventDefault();
        var l = Ladda.create(this);
        var captcha = $("#g-recaptcha-response-1").val();
        if($('#reach-us-modal-form').valid()){
            if(captcha){
                l.start();
                $.ajax({
                    type: "POST",
                    url: $("#contact-us-modal-form").attr('action'),
                    data: $("#contact-us-modal-form").serialize(),
                    statusCode: {
                        200:function (response) {
                            toastr.success("Your enquiry has been send to Admin. We will contact you soon.","Success");
                            l.stop();
                            setTimeout(function(){
                                location.reload();
                            },200);
                            
                        },
                        400:function (response) {
                            toastr.success("Error","Error");
                            l.stop();
                        }
                    }
                });
            }
        }
    });

    $(document).on('click', '.advertisement_enquiry', function(e){
        
        var advertise_id=$(this).attr('data-advertise-id');
        var company_id=$(this).attr('data-company-id');
        $("#advertisement_id").val(advertise_id);
        $("#company_id").val(company_id);
        $("#enquire-advertisements-modal").modal('show');
    });

    $("#send-advertise-enquiry-button").on('click',function(e){
        var l = Ladda.create(this);   
        if($("#advertisements-enquiry-modal-form").valid()){
            l.start();
            $.ajax({
                type: "POST",
                url: $("#advertisements-enquiry-modal-form").attr('action'),
                data: $("#advertisements-enquiry-modal-form").serialize(),
                statusCode: {
                    200:function (response) {
                        toastr.success(response.message, 'Success');
                        $("#enquire-advertisements-modal").modal('hide')
                        l.stop();
                    },
                    400:function (response) {
                        toastr.error(response.responseJSON.message, 'Error');
                        l.stop();
                    }
                }
            });
        }
    });

    $("#advertisements-enquiry-modal-form").validate({
         rules: {
            name: { 
                required: true 
            },
            phone: { 
                required: true, 
                number: true, 
                minlength: 10,
            },
            email: { 
                required: true, 
                email: true 
            },
            message: { 
                required: true
            },
        },
        messages: {
            name: {
                required: "Please enter name"
            },
            phone: {
                required: "Please enter number",
                number: "Enter Valid number",
                maxlength: "please enter atleast 10 digits"
            },
            email: {
                email: "Please enter valid email address",
                required: "Please enter valid email address"
            },
            message: "Please enter message"
        }
    });

    $(window).load(function() {
        $(".ad_card_box .ad-card").addClass('hide');
            if($(".ad_card_box").length > 0){
                $.ajax({
                type: "GET",
                url: $("#api-site-advertise-list").val(),
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
                },
                statusCode: {
                    200:function (response) {
                        if(response.template.length > 0){
                            $('.institute_list').removeClass('col-md-12').addClass('col-md-6');
                            $('.institute_list img').css('height','100px').css('width','100px');
                            $("#featured_advertise_section").removeClass('hide');
                            $(".ad_card_box").empty();
                            $(".ad_card_box").append(response.template);
                            $("#featured-institutes").find(".ins-thumb").removeClass("col-lg-2").addClass("col-lg-3");
                        }else{
                            //$("#featured_companies_section").removeClass('col-md-9').addClass('col-md-12');
                            $("#featured_advertise_section").addClass('hide');
                        }
                    },
                    400:function (response) {
                        $("#featured_advertise_section").addClass('hide');
                    },
                    422:function (response) {

                    }
                }
            });
        }
        
    });

    $(".signin-signup").on('click', function(e){
        if($(this).hasClass('login_page')){
            if($(this).hasClass('new-sigin-link')){
               $("#login-popup").removeClass('hide');
               $("#signup-popup").addClass('hide');
               $(".new-sigin-link").addClass('active');
                $(".new-sigup-link").removeClass('active');
            }
            if($(this).hasClass('new-sigup-link')){
                $("#login-popup").addClass('hide');
                $("#signup-popup").removeClass('hide');
                $(".new-sigup-link").addClass('active');
                $(".new-sigin-link").removeClass('active');
            }
        }
        else{
            if($(this).hasClass('new-sigin-link')){
                $("#signup-popup-modal").modal('hide');
                $("#login-popup-modal").modal();
                $(".new-sigin-link").addClass('active');
                $(".new-sigup-link").removeClass('active');
            }
            if($(this).hasClass('new-sigup-link')){
                $("#login-popup-modal").modal('hide');
                $("#signup-popup-modal").modal()
                $(".new-sigup-link").addClass('active');
                $(".new-sigin-link").removeClass('active');
            }
        }
    });

    $(".home_login_btn").on('click', function(e){
        $(".new-sigin-link").addClass('active');
        $(".new-sigup-link").removeClass('active');
    });

    $(".role-container").on('click', function(e){
        $("#sign-up-btn-modal").attr('data-role', $(this).attr('data-role'));
        $("#sign-up-btn").attr('data-role', $(this).attr('data-role'));
        $(".role-container").removeClass('role-active');
        $(".tic").addClass('tic-uncheck');
        $(this).addClass('role-active');
        $(this).find(".tic").removeClass('tic-uncheck').addClass('tic-check');
    });

    $("#sign-up-btn-modal").on('click', function(e){
        var role = $("#sign-up-btn-modal").attr('data-role');
        
        if(role != 'undefined'){
           if(role == 'seafarer'){
                window.location.href = $("#api-site-seafarer-registration").val();
           }
           if(role == 'company'){
                window.location.href = $("#api-site-company-registration").val();
           }
           if(role == 'institute'){
                window.location.href = $("#api-site-institute-registration").val();
           }
            if(role == 'advertise'){
                window.location.href = $("#api-site-advertise-registration").val();
           }
        }
    });

    $("#sign-up-btn").on('click', function(e){
        var role = $("#sign-up-btn").attr('data-role');
       
        if(role != 'undefined'){
           if(role == 'seafarer'){
                window.location.href = $("#api-site-seafarer-registration").val();
           }
           if(role == 'company'){
                window.location.href = $("#api-site-company-registration").val();
           }
           if(role == 'institute'){
                window.location.href = $("#api-site-institute-registration").val();
           }
           if(role == 'advertise'){
                window.location.href = $("#api-site-advertise-registration").val();
           }
        }
    });

    $('#loginFormModal').validate({
        rules: {
            email: {
                required: true,
                email: true
            },
            password: {
                required: true
            },
        },
        messages: {
            email: {
                required: "Please provide your email address"
            },
        }
    });

    $('#sign-in-btn').on('click', function(e){
        if($('#loginFormModal').valid()){
            var l = Ladda.create(this);
            l.start();
            $.ajax({
                type: "POST",
                url: $("#loginFormModal").attr('action'),
                data: $("#loginFormModal").serialize(),
                statusCode: {
                    200:function (response) {
                        window.location.href = response.redirect_url;
                        l.stop();
                    },
                    400:function (response) {
                        $("#login_error").text(response.responseJSON.message);
                        $("#login_error").css('display','block');
                        l.stop();
                    }
                }
            });

        }
    });
    date = moment().format('DD-MM-YYYY');
    $('.datepicker').datepicker({
        format: "dd-mm-yyyy",
        startDate: date,
        autoClose: true,
        startView: 2
    }).on('changeDate', function(ev) {
        if(ev.viewMode === 0){
            $('.datepicker').datepicker('hide');
        }
   });
        
    $(".search-card").on('click', function(e){
        e.preventDefault();
        
        if($(this).attr('id') == 'home-course-search'){
            window.location.href = $('#api-site-course-search').val();
        }
        else if($(this).attr('id') == 'course-search'){
            window.location.href = $('#api-site-course-search').val();
        }
        else if($(this).attr('id') == 'institute-search'){
            window.location.href = $('#api-site-institute-search').val();
        }
        else{
            $('.institute-search').addClass('home-search-active');
            $('.course-search').removeClass('home-search-active');

            $('.course-search-filter').addClass('hide');
            $('.institute-search-filter').removeClass('hide');
        }
    });

    $(".course_type_filter").on('change', function(e){
        e.preventDefault();
        
        var temp_url = $("#api-institute-course-name-by-course-types").val();
        var url_arr = temp_url.split("%");
        var url = url_arr[0] + parseInt($(this).val());

        $(".other_course_name").val('');
        $("#other_course_name").addClass('hide');

        $.ajax({
            type: "get",
            url: url,
            statusCode: {
                200:function (response) {
                    $(".institute_course_name option").remove();
                    $(".institute_course_name").append('<option value="">Select Course Name</option>');
                    if(response.length > 0){
                        $.each(response,function(index,value){
                            $(".institute_course_name").append('<option value='+value.id+'>'+value.course_name+'</option>');
                        });
                    }
                }
            }
        });
    });

    $(document).on('click', '.seaman-book-enquiry', function(e) {
        var enq_for = $(this).data('enquire-for');
        $("#enquiry_for").val(enq_for);

        $("#seaman-book-enquiry-modal").modal('show');
    });

    $("#send-seaman-book-enquiry-button").on('click',function(e){
        var l = Ladda.create(this);   
        if($("#seaman-book-enquiry-modal-form").valid()){
            l.start();
            $.ajax({
                type: "POST",
                url: $("#seaman-book-enquiry-modal-form").attr('action'),
                data: $("#seaman-book-enquiry-modal-form").serialize(),
                statusCode: {
                    200:function (response) {
                        toastr.success(response.message, 'Success');
                        $("#seaman-book-enquiry-modal").modal('hide')
                        l.stop();
                    },
                    400:function (response) {
                        toastr.error(response.responseJSON.message, 'Error');
                        l.stop();
                    }
                }
            });
        }
    });

    $("#seaman-book-enquiry-modal-form").validate({
         rules: {
            name: { 
                required: true 
            },
            phone: { 
                required: true, 
                number: true, 
                minlength: 10,
            },
            email: { 
                required: true, 
                email: true 
            },
            message: { 
                required: true
            },
        },
        messages: {
            name: {
                required: "Please enter name"
            },
            phone: {
                required: "Please enter number",
                number: "Enter Valid number",
                maxlength: "please enter atleast 10 digits"
            },
            email: {
                email: "Please enter valid email address",
                required: "Please enter valid email address"
            },
            message: "Please enter message"
        }
    });

    $(document).on('init','.featured-company-slick',function (e) {
        $(".featured-company-slick").removeClass("dont-break");
    });

    $('.featured-company-slick').slick({
          dots: false,
          infinite: false,
          speed: 300,
          slidesToShow: 6,
          slidesToScroll: 6,
          prevArrow: '<span class="slider_prev navArrow"><i class="fa fa-chevron-left"></i></span>',
          nextArrow: '<span class="slider_next navArrow"><i class="fa fa-chevron-right"></i></span>',
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: false,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
    });

});